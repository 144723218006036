import '../styles/app.css';
import { Header } from '../shared/components';
import { Stack, HStack, Center, Button, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom/dist';
import { Heading, Image } from '@chakra-ui/react';

export default function Landing() {
  const navigate = useNavigate();

  return (
    <>
      <Box px={{ base: 0, sm: 24 }}>
        <Header />
        <Center>
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            alignItems="stretch"
            gap={{ base: '60px', sm: 10 }}
            mt="40px"
          >
            <Box bg="">
              <Center h="100%">
                <HStack spacing={0}>
                  <Box>
                    <Heading fontSize="4xl" color="#1e2e45">
                      Idena AI
                    </Heading>
                    <Box fontSize="sm">
                      Check AI resistance of your FLIP
                      <br />
                      Verify others' FLIPs during validation ceremonies
                    </Box>

                    <Button
                      mt={5}
                      onClick={() => {
                        navigate(`./flip`, { replace: true });
                      }}
                    >
                      Start
                    </Button>
                  </Box>
                </HStack>
              </Center>
            </Box>
            <Box>
              <Center h="100%">
                <Image
                  maxWidth="450px"
                  width="90%"
                  objectFit="cover"
                  src="/img/schema.png"
                  alt="Idena AI framework"
                />
              </Center>
            </Box>
          </Stack>
        </Center>
      </Box>
    </>
  );
}
