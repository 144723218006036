import { useEffect, useState } from 'react';
import '../styles/app.css';
import { SecondaryButton, Flip, Header } from '../shared/components';
import { HStack, Center, Spacer, Flex, Button, Box } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom/dist';

export default function NewFlip() {
  const [flip, setFlip] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    function submitFlip() {
      axios
        .post('api/flip', {
          flip: flip,
        })
        .then((resp) => {
          if (process.env.NODE_ENV !== 'production')
            console.log('Submitting ', resp);
          navigate(`./${resp?.data?.result?.id}`, { replace: true });
        })
        .catch((err) => {
          const errMessage = `${err}: ${err?.response?.data?.error?.message}`;
          setError(errMessage);
          setIsSubmitting(false);
        });
    }
    if (flip !== null && error === '') submitFlip();
  }, [flip, error]);

  async function setFlipFromClipboard() {
    try {
      const permission = await navigator.permissions.query({
        name: 'clipboard-read',
      });
      if (permission.state === 'denied') {
        return 'Not allowed to read clipboard';
      }
      const clipboardData = await navigator.clipboard.readText();
      try {
        const data = JSON.parse(clipboardData);
        if (
          !(
            data.images?.length === 4 &&
            data.order?.length === 4 &&
            data.originalOrder?.length === 4
          )
        )
          return 'Wrong format. Please copy a flip from the official Idena app.';

        if (data.images.findIndex((img) => img === undefined) >= 0) {
          setFlip(data);
          return 'Empty images are not allowed';
        }

        if (JSON.stringify(data.order) === JSON.stringify(data.originalOrder)) {
          setFlip(data);
          return 'Flip is not shuffled';
        }

        setFlip(data);
      } catch (error) {
        return 'Wrong format. Please copy a flip from the official Idena app.';
      }
    } catch (error) {
      return 'Can not read clipboard';
    }
  }

  return (
    <>
      <Box px={24}>
        <Header />

        <Flip flip={flip} />

        <Flex py={4}>
          <Spacer />
          <Box>
            <Center>
              <HStack spacing={2}>
                <Button
                  isLoading={isSubmitting}
                  loadingText={'Submitting...'}
                  onClick={async () => {
                    setIsSubmitting(true);
                    const error = await setFlipFromClipboard();
                    if (error) {
                      setError(error);
                      setIsSubmitting(false);
                      setFlip(null);
                    } else {
                      setError('');
                    }
                  }}
                >
                  Paste a flip
                </Button>
                {isSubmitting && (
                  <SecondaryButton
                    isDisabled={isSubmitting < 0}
                    onClick={() => {
                      setIsSubmitting(false);
                      setError('');
                      setFlip(null);
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                )}
              </HStack>
            </Center>
            {error !== '' && (
              <Box py="6" color="red" fontSize="sm">
                {error}
              </Box>
            )}
          </Box>
          <Spacer />
        </Flex>
      </Box>
    </>
  );
}
