import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './app';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { uiTheme } from './shared/theme';
import { createBrowserRouter, RouterProvider } from 'react-router-dom/dist';

const root = ReactDOM.createRoot(document.getElementById('root'));

const borderRadius = {
  radii: {
    none: '0',
    sm: '0',
    base: '0',
    md: '0',
    lg: '0',
    xl: '0',
    '2xl': '0',
    '3xl': '0',
    full: '0',
  },
};

const theme = extendTheme({
  ...uiTheme,

  components: {
    Button: {
      ...uiTheme.components.Button,
      baseStyle: {
        ...uiTheme.components.Button.baseStyle,
      },
      variants: {
        ...uiTheme.components.Button.variants,
        base: {
          ...uiTheme.components.Button.variants.base,
          color: '#fff',
          borderRadius: '0',
          bg: '#1e2e45',
          _hover: { bg: '#1e2e45e0' },
          _active: { bg: '#1e2e45' },
          _disabled: {
            bg: '#1e2e45',
            _hover: { color: '#1e2e45' },
          },
        },
        secondary: {
          ...uiTheme.components.Button.variants.secondary,
          color: '#1e2e45',
          borderRadius: '0',
        },
      },
      defaultProps: {
        variant: 'base',
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    ),
  },
]);

root.render(<RouterProvider router={router} />);
