import { useState } from 'react';
import '../styles/app.css';
import { Header, FlipWithAnnotations } from '../shared/components';
import {
  Text,
  HStack,
  Center,
  Spacer,
  Flex,
  Button,
  Box,
} from '@chakra-ui/react';

import axios from 'axios';
import { useInterval } from '../shared/use-interval';
import GaugeChart from 'react-gauge-chart';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';

export default function FlipResult() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [result, setResult] = useState(null);

  const { flipId } = useParams();
  const [isWaiting, setIsWaiting] = useState(flipId);

  useInterval(() => {
    if (isWaiting) {
      axios
        .get('/api/flip', {
          params: {
            id: isWaiting,
          },
        })
        .then((resp) => {
          if (process.env.NODE_ENV !== 'production')
            console.log('Polling: ', resp);

          const newResult = resp?.data?.result;

          let newFlip = '';
          try {
            newFlip = newResult && JSON.parse(newResult.flip);
          } catch (error) {
            setError('Can not parse flip: ' + error);
            setIsWaiting(null);
            return;
          }

          if (!newFlip.images || newFlip.images.length === 0) {
            setError('Invalid flip: no images. Please try again');
            setIsWaiting(null);
            return;
          }

          let newWordsData = {};
          try {
            const strData =
              newResult && newResult.words
                ? newResult.words
                    .replaceAll(`',`, `",`)
                    .replaceAll(`, '`, `, "`)
                    .replaceAll(`,'`, `,"`)
                    .replaceAll(`['`, `["`)
                    .replaceAll(`']`, `"]`)
                : '';
            newWordsData = strData
              ? JSON.parse(`{"newWords": ${strData}}`)
              : { newWords: undefined };
          } catch (error) {
            setError('Can not parse words: ' + newResult?.words);
            return;
          }

          const { newWords } = newWordsData;

          if (newWords && newResult?.score1 && newResult?.score2) {
            const guessAnswerScore =
              newResult.score1 > newResult.score2 ? 1 : 0;

            //Score for solved flip
            const successScore = newResult.score1 > 3.7 ? 1 : 0;
            const successDeltaScore =
              newResult.score1 > newResult.score2 &&
              newResult.score1 - newResult.score2 >= 0.5
                ? 1
                : 0;
            const superSuccessScore = newResult.score1 > 3.9 ? 2 : 0;
            const superSuccessDeltaScore =
              newResult.score1 > newResult.score2 &&
              newResult.score1 - newResult.score2 >= 1.1
                ? 2
                : 0;

            //Score for non solved flip
            const failScore = newResult.score2 > 3 ? 1 : 0;
            const failDeltaScore =
              newResult.score2 > newResult.score1 &&
              failScore &&
              newResult.score2 - newResult.score1 <= 1
                ? 1
                : 0;

            const score = guessAnswerScore
              ? Math.min(
                  2,
                  successDeltaScore +
                    successScore +
                    superSuccessScore +
                    superSuccessDeltaScore
                ) / 2
              : Math.min(2, failDeltaScore) / 2;
            setResult({
              ...newResult,
              flip: newFlip,
              words: newWords,
              score,
            });
            setIsWaiting(null);
          } else if (newWords) {
            setResult({
              ...newResult,
              flip: newFlip,
              words: newWords,
            });
          } else if (newFlip.images.length > 0) {
            setResult({
              flip: newFlip,
            });
          }
        })
        .catch((err) => {
          const errMessage = `${err}: ${err?.response?.data?.error?.message}`;
          setError(`Unknown error. Please try again later. ${errMessage}`);
          setIsWaiting(null);
        });
    }
  }, 2000);

  return (
    <>
      <Box px={24}>
        <Header />

        <FlipWithAnnotations
          words={result?.words}
          flip={result?.flip}
          score1={result?.score1}
          score2={result?.score2}
        />

        {true && (
          <>
            {result?.flip &&
              result?.words &&
              result?.score1 &&
              result?.score2 && (
                <>
                  <Center>
                    <Text>AI resistance</Text>
                  </Center>
                  <Center>
                    <Box px="">
                      <GaugeChart
                        colors={['#ff6666', '#fbf184', '#5ff8ab']}
                        needleColor="#1e2e45"
                        needleBaseColor="#1e2e45"
                        percent={(1 - result?.score) * 0.7 + 0.15}
                        textColor="brandGray.016"
                        style={{ width: '90%' }}
                        hideText={true}
                      />
                    </Box>
                  </Center>
                </>
              )}
            <Flex py={4}>
              <Spacer />
              <Box>
                <Center>
                  <HStack spacing={2}>
                    <Button
                      isLoading={isWaiting}
                      isDisabled={isWaiting}
                      loadingText={'Processing...'}
                      onClick={async () => {
                        navigate(`/flip`, { replace: true });
                      }}
                    >
                      Reset
                    </Button>
                  </HStack>
                </Center>
              </Box>
              <Spacer />
            </Flex>
          </>
        )}

        {error !== '' && (
          <Center>
            <Box py="2" color="red" fontSize="sm">
              {error}
            </Box>
          </Center>
        )}
      </Box>
    </>
  );
}
