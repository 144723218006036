import { Route, Routes } from 'react-router-dom';
import './styles/app.css';
import FlipResult from './pages/flip-result';
import NewFlip from './pages/new-flip';
import Landing from './pages/landing';
import { Box } from '@chakra-ui/react';

function App() {
  return (
    <Box px="13px">
      <Routes>
        <Route path="flip" element={<NewFlip />} />
        <Route path="flip/:flipId" element={<FlipResult />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    </Box>
  );
}

export default App;
