import {
  createIcon,
  Box,
  Button,
  Spacer,
  AspectRatio,
  Flex,
  Image,
  Stack,
  HStack,
  VStack,
  Center,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';

export function Header() {
  return (
    <Box py="7px">
      <Flex>
        <Box>
          <HStack>
            <Center>
              <Link to="/">
                <Image
                  src="/idena_black.svg"
                  alt="Idena logo"
                  boxSize="41px"
                  style={{ marginTop: '-1px' }}
                />
              </Link>
            </Center>

            <Center fontSize="md" color="black">
              <Link to="/">
                <Button
                  p={1}
                  _hover={{ bg: '#fff' }}
                  _active={{ bg: '#fff' }}
                  variant="ghost"
                >
                  Idena AI
                </Button>
              </Link>
            </Center>
          </HStack>
        </Box>
        <Spacer />
        <Center fontSize="md" px={2} role="button" color="black">
          <a target="_blank" href="https://idena.io/download">
            <Button
              _hover={{ bg: '#fff' }}
              _active={{ bg: '#fff' }}
              variant="ghost"
            >
              Node
            </Button>
          </a>

          <a target="_blank" href="https://app.idena.io">
            <Button
              bg="#1e2e45"
              color="#fff"
              borderRadius="0"
              _hover={{ bg: '#1e2e45e0' }}
              _active={{ bg: '#1e2e45' }}
              variant="ghost"
            >
              Web app
            </Button>
          </a>
        </Center>
      </Flex>
    </Box>
  );
}

export function Flip({ flip }) {
  const { images, originalOrder, order } = flip
    ? flip
    : {
        originalOrder: [0, 1, 2, 3],
        order: [0, 1, 2, 3],
        images: [],
      };

  return (
    <Stack py={4} isInline spacing={10} justify="center">
      <FlipImageList>
        {originalOrder.map((num, idx) => (
          <FlipImageListItem
            key={num}
            src={images[num]}
            isFirst={idx === 0}
            isLast={idx === originalOrder.length - 1}
          />
        ))}
      </FlipImageList>
      <FlipImageList>
        {order.map((num, idx) => (
          <FlipImageListItem
            key={num}
            src={images[num]}
            isFirst={idx === 0}
            isLast={idx === order.length - 1}
          />
        ))}
      </FlipImageList>
    </Stack>
  );
}

export function FlipWithAnnotations({
  flip,
  words = ['', '', '', ''],
  score1 = 0,
  score2 = 0,
}) {
  const { images, originalOrder, order } = flip
    ? flip
    : {
        originalOrder: [0, 1, 2, 3],
        order: [0, 1, 2, 3],
        images: [],
        words: ['', '', '', ''],
      };

  return (
    <VStack py={4} spacing={1}>
      <Stack isInline spacing={10} justify="center">
        <FlipAnnotationList>
          {originalOrder.map((num, idx) => (
            <FlipAnnotationItem
              key={num}
              annotation={words[idx]}
              isFirst={idx === 0}
              isLast={idx === originalOrder.length - 1}
            />
          ))}
        </FlipAnnotationList>

        <FlipImageList>
          {originalOrder.map((num, idx) => (
            <FlipImageListItem
              key={num}
              src={images[num]}
              isFirst={idx === 0}
              isLast={idx === originalOrder.length - 1}
            />
          ))}
        </FlipImageList>
        <FlipImageList>
          {order.map((num, idx) => (
            <FlipImageListItem
              key={num}
              src={images[num]}
              isFirst={idx === 0}
              isLast={idx === order.length - 1}
            />
          ))}
        </FlipImageList>
        <FlipAnnotationList>
          {order.map((num, idx) => (
            <FlipAnnotationItem
              key={num}
              annotation={words[originalOrder.indexOf(num)]}
              isFirst={idx === 0}
              isLast={idx === originalOrder.length - 1}
            />
          ))}
        </FlipAnnotationList>
      </Stack>
      {score1 && score2 && (
        <Stack isInline spacing={0} justify="center">
          <Center w={40} fontSize="sm" p={0} color="gray.200">
            {`Score: ${Math.round((score1 / 4) * 100)}%`}
          </Center>
          <Center w={40} fontSize="sm" p={0} color="gray.200">
            {`Score: ${Math.round((score2 / 4) * 100)}%`}
          </Center>
        </Stack>
      )}
    </VStack>
  );
}

export function FlipAnnotationList(props) {
  return <Stack spacing={0} {...props} />;
}

export function FlipAnnotationItem({ isFirst, isLast, isLocked, ...props }) {
  return (
    <FlipAnnotation
      isLocked={isLocked}
      roundedTop={isFirst ? 'md' : 0}
      roundedBottom={isLast ? 'md' : 0}
      borderBottomWidth={isLast ? '1px' : 0}
      width={120}
      {...props}
    />
  );
}

export function FlipAnnotation({
  annotation,
  roundedTop,
  roundedBottom,
  ...props
}) {
  return (
    <AspectRatio
      ratio={4 / 3}
      border="0"
      borderColor="brandGray.016"
      roundedTop={roundedTop}
      roundedBottom={roundedBottom}
      {...props}
    >
      <Center>
        <Text fontSize="sm" p={2}>
          {annotation.charAt(0).toUpperCase() + annotation.slice(1)}
        </Text>
      </Center>
    </AspectRatio>
  );
}

export function FlipImageList(props) {
  return <Stack spacing={0} {...props} />;
}

export function FlipImageListItem({ isFirst, isLast, isLocked, ...props }) {
  return (
    <FlipImage
      isLocked={isLocked}
      roundedTop={isFirst ? 'md' : 0}
      roundedBottom={isLast ? 'md' : 0}
      borderBottomWidth={isLast ? '1px' : 0}
      width={120}
      {...props}
    />
  );
}

export function FlipImage({
  src,
  objectFit = 'scale-down',
  roundedTop,
  roundedBottom,
  ...props
}) {
  return (
    <AspectRatio
      ratio={4 / 3}
      bg="gray.50"
      border="1px"
      borderColor="brandGray.016"
      roundedTop={roundedTop}
      roundedBottom={roundedBottom}
      {...props}
    >
      {src ? (
        <Image
          alt=""
          src={src}
          objectFit={objectFit}
          fallbackSrc="/flips-cant-icn.svg"
          roundedTop={roundedTop}
          roundedBottom={roundedBottom}
        />
      ) : (
        <EmptyFlipImage />
      )}
    </AspectRatio>
  );
}

export function EmptyFlipImage(props) {
  return (
    <Flex align="center" justify="center" px={10} py={6} {...props}>
      <PicIcon boxSize={10} color="gray.200" />
    </Flex>
  );
}

export const PicIcon = createIcon({
  displayName: 'PicIcon',
  viewBox: '0 0 20 20',
  path: (
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M15.984 13.871c.011-.178.016-.408.016-.717V6.846c0-.917-.043-1.142-.164-1.368a.727.727 0 0 0-.314-.314c-.226-.12-.45-.164-1.368-.164H5.846c-.917 0-1.142.043-1.368.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368v6.308c0 .917.043 1.142.164 1.368.033.061.07.114.113.161L7.5 11l2.021 2.31L12.5 10l3.484 3.871zM5.846 3h8.308c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v6.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 0 1-1.135 1.134c-.489.262-.974.401-2.31.401h-8.31c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 0 1 2.4 15.464c-.262-.489-.401-.974-.401-2.31v-6.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 0 1 3.536 3.4C4.024 3.139 4.509 3 5.845 3zM7.5 10a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
    />
  ),
});

export const SecondaryButton = React.forwardRef((props, ref) => (
  <Button ref={ref} variant="secondary" {...props} />
));
